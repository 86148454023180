import React, { useState } from "react";
import "../styles/PackSection.css";
import PackSwitcher from "./PackSwitcher";
import TabComponent from "./TabComponent";
import { growthPack, productPack } from "./packAssets";
import comingSoon from "../assets/images/packs/comingsoon_bg.svg"; // Correctly import the image
import comingSoonMobile from "../assets/images/packs/comingsoon_bg_mobile.svg"; // Correctly import the image

type Pack = {
  title: string;
  subtitle: string;
  tabs: Array<{ label: string; icon: string }>;
  images: {
    [key: string]: {
      desktop: string;
      mobile: string;
    };
  };
};

// New Ops Pack
const opsPack: Pack = {
  title: "LinkX",
  subtitle:
    "Connect apps and data sources without coding, giving your ops teams insights into failure points, demand-supply, and tasks. Automate actions and track analytics seamlessly.    ",
  tabs: [],
  images: {
    default: {
      desktop: comingSoon,
      mobile: comingSoonMobile,
    },
  },
};

// New Support Pack
const supportPack: Pack = {
  title: "DelightX",
  subtitle:
    "Reimagine your customer support by automating every step of the process. Identify patterns in queries, deliver personalized responses, and analyze customer satisfaction and sentiment in real time.    ",
  tabs: [],
  images: {
    default: {
      desktop: comingSoon,
      mobile: comingSoonMobile,
    },
  },
};

// Include the new packs in the packs array
const packs: Pack[] = [growthPack, productPack, opsPack, supportPack];

const PackSection: React.FC = () => {
  const [activePackIndex, setActivePackIndex] = useState(0);
  const [activeTabs, setActiveTabs] = useState<string[]>(
    packs.map((pack) => (pack.tabs.length > 0 ? pack.tabs[0].label : "default"))
  );

  const handleTabClick = (tab: string) => {
    const newActiveTabs = [...activeTabs];
    newActiveTabs[activePackIndex] = tab;
    setActiveTabs(newActiveTabs);
  };

  const handlePrevPack = () => {
    setActivePackIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : packs.length - 1
    );
  };

  const handleNextPack = () => {
    setActivePackIndex((prevIndex) =>
      prevIndex < packs.length - 1 ? prevIndex + 1 : 0
    );
  };

  const activeTab = activeTabs[activePackIndex];
  const images =
    packs[activePackIndex].images[activeTab] ||
    packs[activePackIndex].images["default"];

  const hasTabs = packs[activePackIndex].tabs.length > 0;

  return (
    <div className="container">
      <div className="packSection">
        <div className="topSection">
          <PackSwitcher
            title={packs[activePackIndex].title}
            subtitle={packs[activePackIndex].subtitle}
            onPrevClick={handlePrevPack}
            onNextClick={handleNextPack}
          />
        </div>
        <div className="bottomSection">
          {hasTabs && (
            <div className="leftSection">
              <TabComponent
                tabs={packs[activePackIndex].tabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
          )}
          <div className={`rightSection ${!hasTabs ? "fullWidthImage" : ""}`}>
            <div className="imageContainer">
              {!hasTabs && (
                <>
                  <div className="comingSoonText">Coming Soon</div>
                </>
              )}
              <img
                src={images?.desktop}
                alt={hasTabs ? `${activeTab} Desktop` : "Coming Soon"}
                className="contentImage"
              />
            </div>
            <div className="mobileImageContainer">
              {!hasTabs && (
                <>
                  <div className="comingSoonText">Coming Soon</div>
                </>
              )}
              <img
                src={images?.mobile}
                alt={hasTabs ? `${activeTab} Mobile` : "Coming Soon"}
                className="contentImageMobile"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackSection;
