import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../styles/InputGroup.css";

const InputGroup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleButtonClick = () => {
    if (email) {
      sendEmail(email); // Send email using EmailJS
      sendSlackAlert(email); // Send alert to Slack
      setIsSubmitted(true);
    }
  };

  const sendEmail = (email: string) => {
    const templateParams = {
      from_email: email,
    };

    emailjs.send('service_p620i7s', 'template_c0ftmii', templateParams, 'NdBAxuNOmXN0USicb')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
      });
  };

  const sendSlackAlert = (email: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append(
      "payload",
      JSON.stringify({
        username: "loopx-bot",
        text: `New email submission: ${email}`,
        icon_emoji: ":email:"
      })
    );

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow" as RequestRedirect // Explicitly casting to RequestRedirect
    };

    fetch("https://hooks.slack.com/services/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", requestOptions)
      .then(response => response.text())
      .then(result => console.log("Slack Alert Sent:", result))
      .catch(error => console.error("Error Sending Slack Alert:", error));
  };

  return (
    <div>
      {isSubmitted ? (
        <div className="thank-you-container">
          <span className="thank-you-message">
            Give us a couple of days to get back to you by email.
          </span>
        </div>
      ) : (
        <div className="input-container">
          <input
            type="email"
            placeholder="Please enter your email address"
            value={email}
            onChange={handleInputChange}
            className="input-field"
          />
          <button className="submit-button" onClick={handleButtonClick}>
            Request Access
          </button>
        </div>
      )}
    </div>
  );
};

export default InputGroup;
